'use client'

import React, {useId} from 'react'
import {Input, Label} from '~/design-system/foundations'
import HgButton from '../HgButton'
import HgIcon from '../HgIcon'

export type HgSearchInputProps = {
  placeholder: string
  onChange: (value: string) => void
  value: string
}

const HgSearchInput: React.FC<HgSearchInputProps> = ({
  placeholder,
  onChange,
  value,
}) => {
  const inputId = useId()

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value
    onChange(newValue)
  }

  function handleClear() {
    onChange('')
  }

  return (
    <div className="has-[button:focus]:bg-surface-active grid h-40 min-h-40 w-full min-w-[228px] grid-cols-[1fr_auto] content-center items-center rounded-lg border border-solid border-border-input bg-surface-input px-s3 py-s2 outline-2 outline-border-focus transition-colors duration-300 ease-out focus-within:bg-surface-input-active focus-within:outline hover:bg-surface-input-hover hover:shadow-[0_1px_8px_0_rgba(111,115,150,0.1)] has-[button:focus]:outline-none">
      <Label className="sr-only" htmlFor={inputId}>
        {placeholder}
      </Label>
      <Input
        type="text"
        className="h-full w-full rounded-lg bg-transparent-transparent text-text-default outline-none arcadia-ui-1 placeholder:text-text-subdued"
        placeholder={placeholder}
        onChange={handleInputChange}
        id={inputId}
        value={value}
      />
      {value ? (
        <HgButton
          variant="secondary"
          size="input"
          iconProps={{iconType: 'xmark'}}
          aria-label="cancel"
          onClick={handleClear}
        />
      ) : (
        <div className="flex h-24 w-24 items-center justify-center">
          <HgIcon iconType="search" size="small" />
        </div>
      )}
    </div>
  )
}

export default HgSearchInput
