import HgClickableTableBase from '../HgClickableTable/_components/HgClickableTableBase'
import HgFilterSearch from '../HgFilterSearch'
import DatabaseTableRows from './_components/DatabaseTableRows'
import {type HgDatabaseTableProps} from './types'
import HgPaginationBlock from '../HgPaginationBlock'
import HgItemsPerPageBlock from '../HgItemsPerPageBlock'
import {getFilteredData} from './_utils/getFilteredData'
import {type HgIndexTableRowProps} from '../../components'
import {DATABASE_TABLE_TOP_ID} from './constants'

const PAGINATION_ITEM_THRESHOLD = 50

const HgDatabaseTable = ({
  lastColumnAlignment,
  variant,
  data,
  sortBy = '',
  query = '',
  normalizeRowProps,
  columnTitles,
  hasError,
  filterTabs,
  filterValues,
  filterMap,
  filterDisplayConfig,
  page = '1',
  perPage = '25',
  ...props
}: HgDatabaseTableProps) => {
  const sortableProperties = columnTitles?.length
    ? columnTitles
        .filter(column => column?.isSortable)
        .map(column => column?.columnName as string)
    : []

  const shouldShowPagination = data.length > PAGINATION_ITEM_THRESHOLD

  const filteredData = getFilteredData({
    data,
    query,
    sortBy,
    filterMap,
    filters: filterValues,
    sortableProperties,
  })

  const rows = filteredData
    .map(normalizeRowProps)
    .filter(Boolean) as HgIndexTableRowProps[]

  const itemsPerPage = perPage === 'All' ? perPage : parseInt(perPage)
  return (
    <div id={DATABASE_TABLE_TOP_ID} className="scroll-m-navbar-height">
      <HgFilterSearch
        filterTabs={filterTabs}
        filterDisplayConfig={filterDisplayConfig}
      />
      {shouldShowPagination && (
        <HgItemsPerPageBlock data={rows} controlProps={{itemsPerPage}} />
      )}
      <HgClickableTableBase
        {...props}
        columnTitles={columnTitles}
        lastColumnAlignment={lastColumnAlignment}
        variant={variant}
        tableBody={
          <DatabaseTableRows
            rows={rows}
            lastColumnAlignment={lastColumnAlignment}
            variant={variant}
            hasError={hasError}
            page={page}
            perPage={perPage}
          />
        }
      />
      {shouldShowPagination && (
        <HgPaginationBlock data={rows} controlProps={{itemsPerPage}} />
      )}
    </div>
  )
}

export default HgDatabaseTable
