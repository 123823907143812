import React from 'react'
import {cn} from '~/design-system/utils'

type HgDetailTitleProps = {
  title?: string
  body: string | React.ReactNode
  classNames?: {
    title?: string
    body?: string
  }
}

const HgDetailTitle: React.FC<HgDetailTitleProps> = ({title, body, classNames}) => (
  <div className="flex flex-col gap-s2">
    <p className={cn('text-text-default arcadia-heading-3', classNames?.title)}>
      {title}
    </p>
    {typeof body === 'string' ? (
      <p className={cn('text-text-subdued arcadia-heading-2', classNames?.body)}>
        {body}
      </p>
    ) : (
      <div className={cn('text-text-subdued arcadia-heading-2', classNames?.body)}>
        {body}
      </div>
    )}
  </div>
)

export default HgDetailTitle
