'use client'

import {useCallback} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {Button} from '~/design-system/foundations'
import {HgIcon} from '~/design-system/hg/components'
import {unreachableCase} from '~/design-system/utils'

const sortButtonVariants = tv({
  slots: {
    button:
      'group flex h-16 max-h-16 w-16 max-w-16 items-center justify-center rounded p-[2px] pt-[3px] opacity-0 opacity-0 outline-2 -outline-offset-2 outline-border-focus transition-opacity duration-300 hover:opacity-100 focus-visible:opacity-100 focus-visible:outline',
    sortIcon:
      'transition-color max-w-[10px] text-icon-subdued transition-transform duration-300 group-active:text-icon-default',
  },
  variants: {
    sortOrder: {
      asc: {
        button: 'opacity-100',
        sortIcon: 'text-icon-default',
      },
      desc: {
        button: 'opacity-100',
        sortIcon: 'rotate-180 text-icon-default',
      },
    },
  },
})

type HgSortButtonVariantProps = VariantProps<typeof sortButtonVariants>
export type SortOrder = HgSortButtonVariantProps['sortOrder']

export type HgSortButtonProps = {
  handleSort?: (sortOrder: SortOrder) => void
  sortOrder?: SortOrder
  className?: string
}

const HgSortButton = ({handleSort, sortOrder, className}: HgSortButtonProps) => {
  const {button, sortIcon} = sortButtonVariants({
    sortOrder,
  })
  const getNextSortOrder = useCallback(() => {
    switch (sortOrder) {
      case undefined:
        return 'asc'
      case 'asc':
        return 'desc'
      case 'desc':
        return undefined
      default:
        unreachableCase(sortOrder)
    }
  }, [sortOrder])

  const handleClick = () => {
    if (!handleSort) return
    const nextSortOrder = getNextSortOrder()
    handleSort(nextSortOrder)
  }

  return (
    <Button className={button({class: className})} onClick={handleClick}>
      <HgIcon className={sortIcon()} iconType="sort-up" size="regular" />
      <span className="sr-only">Sort {getNextSortOrder()}</span>
    </Button>
  )
}

export default HgSortButton
