import * as React from 'react'
import {tv} from 'tailwind-variants'
import {TableHead, TableHeader, TableRow} from '~/design-system/foundations/Table'
import {cn} from '~/design-system/utils'
import {type HgPricingPlanSelection} from '..'
import HgPricingTableCell from './PricingTableCell'
import styles from './styles.module.css'

const stickyHeaderVariants = tv({
  base: 'sticky top-0 z-30 w-full bg-background-default pt-navbar-height text-text-default transition-colors duration-[350ms] arcadia-heading-3 md:-top-1 md:z-10 md:h-navbar-height md:bg-transparent-transparent',
  slots: {
    tableRow:
      'hidden w-full grid-flow-col content-center gap-s4 border-none bg-background-default duration-[350ms] md:grid md:grid-cols-[repeat(4,minmax(0,1fr))]',
  },
})

const dropdownVariants = tv({
  slots: {
    dropdownWrapper:
      'z-30 flex grid w-full grid-cols-2 justify-center gap-s4 !border-b-0 bg-background-default pb-1 pt-s2 opacity-100 arcadia-heading-3 md:hidden',
    dropdown:
      'border-1 h-40 w-full appearance-none self-stretch rounded-lg border border-solid border-border-input bg-surface-input bg-no-repeat px-s2 py-s1 text-text-default opacity-100 hover:ring-2 focus:outline-none focus:ring-2 focus:ring-border-focus',
    cell: 'w-full',
    label: 'text-text-subdued arcadia-body-3',
  },
})

type PlanOption = {
  value: string
  label: string
}

type HgPricingTableDropdownProps = {
  selectedPlans: HgPricingPlanSelection
  onSelectionChange: (selection: HgPricingPlanSelection) => void
  planOptions: PlanOption[]
}

type HgStickyPricingHeaderProps = {
  planTitles: string[]
} & HgPricingTableDropdownProps

const SelectDropdown: React.FC<{
  label: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  options: PlanOption[]
}> = ({label, value, onChange, options}) => {
  const {dropdown, label: labelClass} = dropdownVariants()

  return (
    <TableHead className="flex w-full flex-col gap-s1">
      <label className={labelClass()}>{label}</label>
      <select
        value={value}
        onChange={onChange}
        className={cn(dropdown(), styles.selectBackground)}
      >
        {options.map((opt, idx) => (
          <option value={opt.value} key={idx}>
            {opt.label}
          </option>
        ))}
      </select>
    </TableHead>
  )
}

const HgPricingTableDropdown: React.FC<HgPricingTableDropdownProps> = ({
  selectedPlans,
  onSelectionChange,
  planOptions,
}) => {
  const {dropdownWrapper} = dropdownVariants()

  const handlePrimaryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPrimaryPlan = event.target.value
    onSelectionChange({
      primaryPlan: newPrimaryPlan,
      secondaryPlan:
        newPrimaryPlan !== selectedPlans.secondaryPlan
          ? selectedPlans.secondaryPlan
          : '',
    })
  }

  const handleSecondaryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSecondaryPlan = event.target.value
    onSelectionChange({
      ...selectedPlans,
      secondaryPlan: newSecondaryPlan === 'none' ? '' : newSecondaryPlan,
    })
  }

  const secondaryOptions = [
    {value: 'none', label: '---'},
    ...planOptions.filter(option => option.value !== selectedPlans.primaryPlan),
  ]

  return (
    <TableRow className={dropdownWrapper()}>
      <SelectDropdown
        label="Plan Type"
        value={selectedPlans.primaryPlan}
        onChange={handlePrimaryChange}
        options={planOptions}
      />
      <SelectDropdown
        label="Plan Type"
        value={selectedPlans.secondaryPlan}
        onChange={handleSecondaryChange}
        options={secondaryOptions}
      />
    </TableRow>
  )
}

const HgStickyPricingHeader = React.forwardRef<
  HTMLTableSectionElement,
  HgStickyPricingHeaderProps
>(({planTitles, selectedPlans, onSelectionChange, planOptions}, ref) => {
  const {base, tableRow} = stickyHeaderVariants()
  return (
    <TableHeader className={base()} ref={ref}>
      <HgPricingTableDropdown
        planOptions={planOptions}
        selectedPlans={selectedPlans}
        onSelectionChange={onSelectionChange}
      />
      <TableRow className={tableRow()}>
        <HgPricingTableCell cell={{type: 'empty'}} isHeader />
        {planTitles.map((plan, idx) => (
          <HgPricingTableCell key={idx} cell={{type: 'plan', body: plan}} isHeader />
        ))}
      </TableRow>
    </TableHeader>
  )
})

HgStickyPricingHeader.displayName = 'HgStickyPricingHeader'

export default HgStickyPricingHeader
