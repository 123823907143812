import Skeleton from 'react-loading-skeleton'
import {
  HgAspectRatio,
  HgIcon,
  HgTagGroup,
  type HgIconType,
  type HgTagProps,
} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'

export type HgIndexTableBodyCellContentProps = {
  isLoading?: boolean
  title?: React.ReactNode
  body?: React.ReactNode
  iconType?: HgIconType
  image?: React.ReactNode
  tags?: HgTagProps[]
  className?: string
}

export const HgIndexTableBodyCell = ({
  isLoading,
  title,
  body,
  iconType,
  image,
  tags,
  className,
}: HgIndexTableBodyCellContentProps) => {
  if (isLoading) {
    return (
      <div className={cn('flex h-full w-full items-center', className)}>
        <Skeleton containerClassName="flex-1" />
      </div>
    )
  }
  if (iconType) {
    return (
      <div className={cn('flex h-full w-full items-center', className)}>
        <HgIcon
          size="small"
          iconType={iconType}
          className="text-icon-default md:hidden"
        />
        <HgIcon
          size="regular"
          iconType={iconType}
          className="hidden text-icon-default md:block lg:hidden"
        />
        <HgIcon
          size="large"
          iconType={iconType}
          className="hidden text-icon-default lg:block"
        />
      </div>
    )
  }

  if (tags) {
    return (
      <div className={cn('flex h-full w-full items-center', className)}>
        <HgTagGroup canWrap={false} tags={tags} />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'flex h-full w-full flex-row items-center space-x-s2 overflow-hidden',
        className
      )}
    >
      {image && (
        <HgAspectRatio
          aspectRatio="1:1"
          classNames={{
            wrapper:
              'flex h-full max-h-40 min-h-40 w-full min-w-40 max-w-40 md:max-h-48 md:min-h-48 md:min-w-48 md:max-w-48 lg:max-h-56 lg:min-h-56 lg:min-w-56 lg:max-w-56 [&>*]:h-full [&_svg]:h-full',
          }}
        >
          {image}
        </HgAspectRatio>
      )}
      <div className="flex flex-col items-start justify-items-center">
        {title && (
          <p className="flex flex-1 items-center text-text-default arcadia-heading-3">
            {title}
          </p>
        )}
        {body && (
          <p className="flex flex-1 items-center text-text-default arcadia-body-2">
            {body}
          </p>
        )}
      </div>
    </div>
  )
}
