'use client'

import {grid, gridItem} from '~/design-system/foundations'
import HgPagination from '../../components/HgPagination'
import HgResultsWindow from '../../components/HgResultsWindow'
import {useRouter, useSearchParams} from 'next/navigation'
import useQueryStringGenerator from '~/design-system/hooks/useQueryStringGenerator'
import {DATABASE_TABLE_TOP_ID} from '../HgDatabaseTable/constants'

export type HgPaginationBlockProps = {
  data: Array<Record<string, any>>
  controlProps?: {
    currentPage?: number
    windowStart?: number
    windowEnd?: number
    onChange?: (page: string) => void
    itemsPerPage?: number | 'All'
  }
}

export function usePaginationProps({data, controlProps}: HgPaginationBlockProps) {
  const searchParams = useSearchParams()

  const currentPage =
    controlProps?.currentPage ?? parseInt(searchParams.get('page') || '1')
  const itemsPerPageParam =
    controlProps?.itemsPerPage?.toString() ?? searchParams.get('perPage') ?? '25'

  const itemsPerPage =
    itemsPerPageParam === 'All' ? ('All' as const) : parseInt(itemsPerPageParam)

  const windowStart =
    controlProps?.windowStart ?? itemsPerPage === 'All'
      ? 1
      : (currentPage - 1) * itemsPerPage + 1
  const windowEnd = Math.min(
    controlProps?.windowEnd ?? itemsPerPage === 'All'
      ? data.length
      : currentPage * itemsPerPage,
    data.length
  )

  return {
    currentPage,
    itemsPerPage,
    windowStart,
    windowEnd,
  }
}

export default function HgPaginationBlock({
  data,
  controlProps,
}: HgPaginationBlockProps) {
  const queryStringGenerator = useQueryStringGenerator()
  const router = useRouter()
  const {currentPage, itemsPerPage, windowStart, windowEnd} = usePaginationProps({
    data,
    controlProps,
  })

  if (data.length === 0) {
    return null
  }

  return (
    <div className={grid({class: 'mx-auto w-full pt-s6'})}>
      <div
        className={gridItem({
          size: 'max',
          className: 'flex items-center justify-between',
        })}
      >
        <HgPagination
          onChange={page => {
            controlProps?.onChange?.(page)
            router.push(queryStringGenerator({page}) + `#${DATABASE_TABLE_TOP_ID}`)
          }}
          totalPages={
            itemsPerPage === 'All' || controlProps?.itemsPerPage === 'All'
              ? 1
              : Math.ceil(
                  data.length / (itemsPerPage || controlProps?.itemsPerPage || 25)
                )
          }
          currentPage={currentPage}
          nextPageLink={
            queryStringGenerator({page: `${currentPage + 1}`}) +
            `#${DATABASE_TABLE_TOP_ID}`
          }
          prevPageLink={
            queryStringGenerator({page: `${currentPage - 1}`}) +
            `#${DATABASE_TABLE_TOP_ID}`
          }
        />
        <HgResultsWindow
          resultsLength={data.length}
          windowStart={windowStart}
          windowEnd={windowEnd}
        />
      </div>
    </div>
  )
}
